const nowOutput = `
<p><strong>What I'm doing now</strong><br />
<i>Updated <strong>November 12the, 2024</strong>, from my home in South Africa, where it's the start of Spring.</i></p>
<ol>
<li>Finished my new book, The Flow of Life: 365 Days with Tao. Can view on <a href="https://www.amazon.com/dp/B0DPGPSQ1C">Amazon</a></li>
<li>My day job is consulting to a UX agenecy in the US. iiimpact.io. Diving deep into the Cyber Security space. Working on a new exiting project.</li>
<li>Ongoing promotion and creating videos for my apps. Building them was easy but marketing I find very challenging!</li>
<li>Summer has arrived. Wind has begun blowing so back on the water Kite Boarding</li>
</ol>

<hr>
  <p class="commands-text">Available commands: - help - about - now - apps - books - portfolio - paintings  - uiux - contact - code - taoism<br />themes (default, snow, mist, zen, tao, mountain)  - clear</p>
`;
export default nowOutput;